/** Cosmos 954: Toothpick **/

import React from "react";
import { preloadImages, scaleFullscreen, showText, hideText, positionText, resetAnimationDelay } from "../../helpers"
import PrevNext from "../../components/navigation/prevnext"
import SEO from "../../components/seo"
import { gsap, Linear } from "gsap";

let globalState, data, preloadData, voTimeout, tpTimeout, handTL;


class CosmosPage3 extends React.Component {

    constructor(props) {
        super(props);

        //update global state and assets data
        globalState = this.props.location.state;
        data = globalState.assets[3];
        preloadData = globalState.assets[4];

        //refs
        this.fullscreenBg = null;
    }

    state = {
        textVisible: false,
    }




    componentDidMount = () => {

        //position text
        positionText(this.refs.text1Ref, "left", "top", globalState.textPosition.leftTop);

        //show text
        gsap.to(this.refs.text1Ref, 0, { autoAlpha: 1.0 });

        //add base track
        globalState.baseAudioRef.updateTrack(1, globalState.cdnUrl + data.soundfile1); //toothpick 

        voTimeout = setTimeout(() => {
            //add vo track
            globalState.baseAudioRef.updateTrack(3, globalState.cdnUrl + data.soundfile3); //fight VO 
            //update volume base track
            globalState.baseAudioRef.updateVolume(2, 60);
          }, 1500);

        //Update dimensions
        this.updateDimensions();

        //Add resize listener
        window.addEventListener("resize", this.updateDimensions);


        gsap.to(this.refs.toothpickRef, 1.5, {rotation: 360, ease: Linear.easeNone, repeat: -1});
        
        tpTimeout = setTimeout(() => {
            this.initToothpickAnimation();
        }, 6000);


        //Preload assets from next page
        preloadImages([
            globalState.cdnUrl + preloadData.text1,
            globalState.cdnUrl + preloadData.text2,
            globalState.cdnUrl + preloadData.caption,
            globalState.cdnUrl + preloadData.slide1,
            globalState.cdnUrl + preloadData.slide2,
            globalState.cdnUrl + preloadData.slide3,
            globalState.cdnUrl + preloadData.slide4,
            globalState.cdnUrl + preloadData.slide5,
            globalState.cdnUrl + preloadData.slide6,
            globalState.cdnUrl + preloadData.slide7,
            globalState.cdnUrl + preloadData.slide8,
        ], this.preloadCallback);

    }

    preloadCallback = () => {
        this.refs.prevNextRef.showPrevNextBtns(globalState.showPrevNextDelay);
    }



    componentWillUnmount() {
        //Kill any tweens
        gsap.killTweensOf(this.refs.text1Ref);
        gsap.killTweensOf(handTL);

        //Clear vo track timeout
        clearTimeout(voTimeout);
        clearTimeout(tpTimeout);

        //clean up some animation tweens


        window.removeEventListener("resize", this.updateDimensions);
    }


    toggleTextDisplay = (event) => {

        //toggle text visibility
        this.setState({ textVisible: !this.state.textVisible });

        //reset the animation delay
        resetAnimationDelay();

        if (this.state.textVisible) {
            //show text
            showText(this.refs.text1Ref, "left", "top", globalState.textPosition.leftTop);


        } else {
            //hide text
            hideText(this.refs.text1Ref, "left");

        }
    }

    updateDimensions = () => {
        console.log('updateDimensions');
        //background native size is 1288 x 779
        scaleFullscreen(this.refs.fullscreenBgRef, window.innerWidth, window.innerHeight, 1288, 779, false);
    }

    initToothpickAnimation = () => {

        this.refs.handWrapperRef.classList.add("hand1");
        
        handTL = gsap.timeline();
        let h1 = this.refs.hand1Ref;
        let h2 = this.refs.hand2Ref;
        let h3 = this.refs.hand3Ref;
        let h4 = this.refs.hand4Ref;
        let h5 = this.refs.hand5Ref;
        let h6 = this.refs.hand6Ref;
        let time = 0.7;
        let e = Linear.easeNone;

        //1
        handTL.fromTo(h1, {x:"262px", y:"297px", rotate:"25deg"}, { x:"-121px", y:"195px", rotate:"0deg", onComplete:this.updateHand, onCompleteParams:[2], duration:time, ease:e});
        
        
        //2
        handTL.fromTo(h2, {x:"-185px", y:"216px", rotate:"0deg"}, { x:"-347px", y:"199px", onComplete:this.updateHand, onCompleteParams:[3], duration: time, ease:e});
        
        
        //3
        handTL.fromTo(h3, {x:"-282px" , y:"123px"}, { x:"-419px", y:"100px", onComplete:this.updateHand, onCompleteParams:[4], duration: time+0.05, ease:e});

        //4
        handTL.fromTo(h4, {x:"-539px", y:"77px", rotate:"12deg"}, {x:"-549px", y:"113px", rotate:"0deg", onComplete:this.updateHand, onCompleteParams:[5], duration: time+0.1, ease:e});
        
        //5
        handTL.fromTo(h5, {x:"-342px", y:"106px"}, {x:"-342px", y:"106px", onComplete:this.updateHand, onCompleteParams:[6], duration: time-0.5, ease:e});

        //6
        handTL.fromTo(h6, {x:"-364px", y:"32px", rotate:"15deg"}, {x:"-364px", y:"32px", rotate:"15deg", onComplete:this.updateHand, onCompleteParams:[6], duration: time-0.5, ease:e});
        //6
        handTL.fromTo(h6, {x:"-364px", y:"32px", rotate:"15deg"}, {x:"431px", y:"300px", rotate:"0deg", onComplete:this.updateHand, onCompleteParams:[6], duration: time-0.2, ease:e});
        
        
    }

    updateHand = (num) => {
        try{
            if(num === 6){
                gsap.killTweensOf(this.refs.toothpickRef);
                gsap.set(this.refs.toothpickRef, {autoAlpha:0});
            }
            this.refs.handWrapperRef.classList.remove("hand"+(num-1));
            this.refs.handWrapperRef.classList.add("hand"+num);
        } catch (e){
            
        }
    }

    isTransitioning = () => {
        gsap.killTweensOf(handTL);
        gsap.killTweensOf(this.refs.toothpickRef);
        //pause animations
    }


    render() {

        return (

            <>

                <link rel="prefetch" href="/cosmos-954/4" />

                <PrevNext globalState={globalState} ref="prevNextRef"
                    nextLocation="/cosmos-954/4" prevLocation="/cosmos-954/2"
                    isTransitioning={this.isTransitioning} />
                {data && (
                <div className="fullpage-wrapper">
                    <SEO title="Pine Point - Cosmos 954" />
                    

                    <div className="fullscreen-bg pinepointers-bg" ref="fullscreenBgRef">
                        <img src={globalState.cdnUrl + data.background} alt="background" className="background" />
                    </div>
                    
                    

                    <div className="handWrapper" ref="handWrapperRef">
                        <div ref="hand1Ref" className="hand hand1">
                            <img width="606" height="392" src={globalState.cdnUrl + data.hand1} alt="hand" />
                        </div>
                        <div ref="hand2Ref" className="hand hand2">
                            <img width="792" height="391" src={globalState.cdnUrl + data.hand2} alt="hand" />
                        </div>
                        <div ref="hand3Ref" className="hand hand3">
                            <img width="880" height="477" src={globalState.cdnUrl + data.hand3} alt="hand" />
                        </div>
                        <div ref="hand4Ref" className="hand hand4">
                            <img width="1288" height="779" src={globalState.cdnUrl + data.hand4} alt="hand" />
                        </div>
                        <div ref="hand5Ref" className="hand hand5">
                            <img width="960" height="496" src={globalState.cdnUrl + data.hand7} alt="hand" />
                        </div>
                        <div ref="hand6Ref" className="hand hand6">
                            <img width="854" height="553" src={globalState.cdnUrl + data.hand8} alt="hand" />
                        </div>
                    </div>

                    <div className="toothpickAnimationWrapper" ref="toothpickAnimationRef">
                        <img src={globalState.cdnUrl + data.toothpick} alt="toothpick" className="toothpick" ref="toothpickRef" />
                    </div>

                    <div className="text-wrapper">
                        <div className="text text--right-top" ref="text1Ref">
                            <input type="image" src={globalState.cdnUrl + data.text1} style={{ marginRight: "4vw", marginTop: "0" }} onClick={this.toggleTextDisplay} alt="Richard told me a lot of stories about fighting. My favorite is the one where he punched a man who was chewing a toothpick. As the man fell to the ground, Richard snapped the still-spinning toothpick out of the air. Can that be the way it really happened? In Richard’s memories, he’s the undefeated champ." />
                        </div>
                    </div>

                </div>
                )}
            </>
        );
    }


};

export default CosmosPage3
